<style></style>
<script>
import {AccountAuthProvider} from "@/enums";
export default {
  props: ['cftools_id', 'account', 'isMobile'],
  name: "AccountSecurity",
  components: {
    PasswordSetup: () => import("@/components/lazy/account/PasswordSetup"),
    PasswordChange: () => import("@/components/lazy/account/PasswordChange"),
    PasswordDisable: () => import("@/components/lazy/account/PasswordDisable"),
    EMailChange: () => import("@/components/lazy/account/EMailChange"),
    TwoFAuthSetup: () => import("@/components/lazy/account/2FASetup.vue"),
    Disable2FA: () => import("@/components/lazy/account/Disable2FA.vue")
  },
  methods: {
    onPasswordEnabled() {
      this.account.authentication.provider = AccountAuthProvider.NATIVE;
    },
    onPasswordDisabled() {
      this.account.authentication.provider = AccountAuthProvider.STEAM;
    },
    on2FADisabled() {
      this.account.authentication["2fa"] = false;
      this.$swal({
        icon: 'success',
        text: this.$t('account.security.2fa.disable.disabled_alert')
      })
    },
    on2FAEnabled() {
      this.account.authentication["2fa"] = true;
      this.$swal({
        icon: 'success',
        text: this.$t('account.security.2fa.process.success')
      });
    },
    onEMailChanged(address) {
      this.account.email.address = address;
      this.$swal({
        icon: 'info',
        text: this.$t('account.email.change.confirmed')
      });
    },
  },
  data() {
    return {
      AccountAuthProvider: AccountAuthProvider,
    }
  }
}
</script>

<template>
  <div>
    <div v-if="account.authentication">
      <div class="row">
        <div class="col-lg-11">
          <div class="row">
            <div class="col">
              <div class="card">
                <div class="card-body">
                  <div role="tablist">
                    <!-- Authentication provider -->
                    <b-card no-body class="shadow-none">
                        <b-card-header header-tag="header" role="tab">
                          <h3 class="m-0 d-inline-flex">
                            {{ $t('account.security.provider.label') }}
                          </h3>
                          <div class="d-inline-flex ml-1 text-muted">
                            {{ $t('account.security.provider.description') }}
                          </div>
                        </b-card-header>
                        <b-card-body>
                          <b-card-text>
                            <template>
                              <div class="form-group">
                                <div v-if="account.authentication.provider === AccountAuthProvider.NATIVE">
                                  <div class="row justify-content-evenly">
                                    <div class="col-lg-10">
                                      <h2>
                                        <i class="fad fa-key-skeleton"></i>
                                        {{ $t('account.security.provider.native.label') }}
                                      </h2>
                                    </div>
                                    <div class="col-lg-2">
                                      <div class="btn-group">
                                        <PasswordChange></PasswordChange>
                                        <PasswordDisable @passwordDisabled="onPasswordDisabled"></PasswordDisable>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div v-else-if="account.authentication.provider === AccountAuthProvider.STEAM">
                                  <div class="row justify-content-evenly">
                                    <div class="col-lg-9">
                                      <h2>
                                        <i class="fab fa-steam"></i> {{ $t('account.security.provider.steam.label') }}
                                      </h2>
                                    </div>
                                    <div class="col-lg-3">
                                      <PasswordSetup @passwordEnabled="onPasswordEnabled"></PasswordSetup>
                                    </div>
                                  </div>
                                </div>
                                <div v-else>
                                  <h5 class="text-danger">{{ $t('account.security.provider.unknown') }}</h5>
                                </div>
                              </div>
                            </template>
                          </b-card-text>
                        </b-card-body>
                    </b-card>
                    <!-- Authentication provider end.-->
                    <!-- Password change -->
                    <b-card no-body class="shadow-none">
                        <b-card-header header-tag="header" role="tab">
                          <h3 class="m-0 d-inline-flex">
                            {{ $t('account.security.email.label') }}
                          </h3>
                          <div class="d-inline-flex ml-1 text-muted">
                            {{ $t('account.security.email.description') }}
                          </div>
                        </b-card-header>
                        <b-card-body>
                          <b-card-text>
                            <template>
                              <div class="row">
                                <div class="col-md-12">
                                  <div class="form-group">
                                    <div class="input-group">
                                      <input
                                          id="cf-email"
                                          type="text"
                                          class="form-control"
                                          placeholder="First name"
                                          :value="account.email.address"
                                          disabled="disabled"
                                      />
                                      <div class="input-group-append">
                                        <EMailChange @addressChanged="onEMailChanged"></EMailChange>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </template>
                          </b-card-text>
                        </b-card-body>
                    </b-card>
                    <!-- Password change end.-->
                    <!-- 2FA Setup -->
                    <b-card no-body class="shadow-none">
                      <b-card-header header-tag="header" role="tab">
                        <h3 class="m-0 d-inline-flex">
                          {{ $t('account.security.2fa.label') }}
                        </h3>
                        <div class="d-inline-flex ml-1 text-muted">
                          {{ $t('account.security.2fa.description') }}
                        </div>
                      </b-card-header>
                      <b-card-body>
                        <b-card-text>
                          <template>
                            <div class="form-group">
                            <div v-if="account.authentication['2fa']">
                              <div class="row ">
                                <div class="col-lg-10">
                                  <h2>
                                    <i class="fad fa-qrcode"></i>
                                    {{ $t('account.security.2fa.short_label_disable') }}
                                  </h2>
                                </div>
                                <div class="col-lg-2 row flex-row-reverse" style="margin-left: 2px;">
                                  <div class="btn-group">
                                    <Disable2FA @twoFADisabled="on2FADisabled"></Disable2FA>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div v-else>
                              <div class="row justify-content-evenly">
                                <div class="col-lg-9">
                                  <h2>
                                    <i class="fad fa-qrcode"></i>
                                    {{ $t('account.security.2fa.short_label_setup') }}
                                  </h2>
                                </div>
                                <div class="col-lg-3 row flex-row-reverse ml-1">
                                  <TwoFAuthSetup :account="account" :isMobile="isMobile" @2FAEnabled="on2FAEnabled"></TwoFAuthSetup>
                                </div>
                              </div>
                            </div>
                            </div>
                          </template>
                        </b-card-text>
                      </b-card-body>
                    </b-card>
                    <!-- 2FA Setup end. -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="row">
        <div class="col-lg">
          <div class="card border border-danger">
            <div class="card-header bg-transparent border-danger">
              <h5 class="my-0 text-danger">
                <i class="far fa-exclamation-circle mr-3"></i>
                {{ $t('account.error.overview.title') }}
              </h5>
            </div>
            <div class="card-body">
              <h5 class="card-title mt-0"> {{ $t('account.error.overview.description') }}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>